import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ConditionsPage = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <div style={{ maxWidth: "900px", margin: "auto" }}>
      <h1 style={{ textAlign: "center", marginTop: "2rem" }}>
        TERMS AND CONDITIONS
      </h1>
      <h2>Adverse weather conditions</h2>
      <p>
        In the event of inclement weather which in our opinion would pose a risk
        to health & safety of our personnel or equipment or has the potential to
        prevent successful video or audio capture, we reserve the right to
        change the date or time of filming to a more suitable date or time.
      </p>
    </div>
  </Layout>
)

export default ConditionsPage
